import { useEffect, useState, useRef } from "react"
import { useCreateAdEvent } from "@/api/analytics/hooks"
import { useGetBanners } from "@/api/useBanners"
import Image from "next/image"
import { AdPosition, BannerSizeType } from "swagger/models"
import { EVENT_TYPE } from "@/api/analytics/api"

type Props = {
  position: AdPosition
}

const BIG_BANNER_WIDTH = 970
const BIG_BANNER_HEIGHT = 210
const SMALL_BANNER_WIDTH = 300
const SMALL_BANNER_HEIGHT = 250

export const BeBanner = ({ position }: Props) => {
  const { banners } = useGetBanners()
  const { mutateAsync } = useCreateAdEvent()
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const bannerRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < BIG_BANNER_WIDTH)
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const bannerSize = isSmallScreen ? BannerSizeType.SMALL : BannerSizeType.BIG
  const selectedBanner = banners?.find((banner) => banner.size === bannerSize)

  useEffect(() => {
    if (bannerRef.current && selectedBanner) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(async (entry) => {
            if (entry.isIntersecting) {
              await mutateAsync({
                bannerId: selectedBanner.bannerId,
                adPosition: position,
                time: Date.now(),
                event: EVENT_TYPE.AD_SHOWN,
              })
            }
          })
        },
        {
          threshold: 1.0,
        },
      )

      observer.observe(bannerRef.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [mutateAsync, position, selectedBanner])

  if (!selectedBanner) {
    return null
  }

  const { imageUrl, adUrl } = selectedBanner
  const bannerWidth = bannerSize === BannerSizeType.SMALL ? SMALL_BANNER_WIDTH : BIG_BANNER_WIDTH
  const bannerHeight = bannerSize === BannerSizeType.SMALL ? SMALL_BANNER_HEIGHT : BIG_BANNER_HEIGHT

  const handleClick = async () => {
    await mutateAsync({
      bannerId: selectedBanner.bannerId,
      adPosition: position,
      time: Date.now(),
      event: EVENT_TYPE.AD_CLICKED,
    })
  }

  return (
    <a
      className="flex w-full cursor-pointer items-center justify-center"
      href={adUrl}
      onClick={handleClick}
      ref={bannerRef}
    >
      <Image
        src={imageUrl}
        width={bannerWidth}
        height={bannerHeight}
        alt="Banner"
        style={{
          height: bannerHeight,
          width: bannerWidth,
        }}
        priority
      />
    </a>
  )
}
