import { axiosInstance } from "@/api/axiosInstance"
import { AxiosRequestConfig } from "axios"
import { CreateAnalyticsAdEventRequest, CreateAnalyticsComicEventRequest, UpdateUserEmailRequest } from "swagger/models"
import { AnalyticsApi } from "../../../swagger/api"
import { Configuration } from "../../../swagger/configuration"
import { getFirebaseIDToken } from "../auth/firebase"

export enum EVENT_TYPE {
  // GAME
  COMIC_START = "comic-start",
  GAME_CLAIMED = "game-claimed",
  LOCATION_CLAIMED = "location-claimed",
  BONUS_CLAIMED = "bonus-claimed",
  QUESTION_SHOWN = "question-shown",
  QUESTION_DURATION = "question-duration",
  COMIC_DURATION = "comic-duration",
  QUESTION_ANSWER = "question-answered",
  // ADS
  AD_SHOWN = "ad-shown",
  AD_CLICKED = "ad-clicked",
}

const getAccessToken = async () => {
  const accessToken = await getFirebaseIDToken()
  return accessToken
}

const getApiConfig = () =>
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_API_URL,
    //@ts-ignore
    accessToken: getAccessToken,
  })

const apiConfiguration = getApiConfig()

const ANALYTICS_API = new AnalyticsApi(apiConfiguration, apiConfiguration.basePath, axiosInstance)

export const createGameEvent = async (body: CreateAnalyticsComicEventRequest, options?: AxiosRequestConfig) =>
  ANALYTICS_API.createAnalyticsComicEvent(body, options)

export const createAdEvent = async (body: CreateAnalyticsAdEventRequest, options?: AxiosRequestConfig) =>
  ANALYTICS_API.createAnalyticsAdEvent(body, options)
