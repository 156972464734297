/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BannerState {
    PUBLISHED = 'PUBLISHED',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    SUSPENDED = 'SUSPENDED',
    DONE = 'DONE'
}

