import React, { useState } from "react"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { twMerge } from "tailwind-merge"

const ToolTip: React.FC<{ text: string; children: React.ReactElement }> = ({ text, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isOpen && <div className="fixed left-0 top-0 z-50 h-dvh w-screen bg-primary-700/60" />}

      <div>
        <div
          data-tip={text}
          data-tooltip-id="my-tooltip"
          className={twMerge("relative w-full cursor-pointer", isOpen && "z-[100]")}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {children}
        </div>

        <ReactTooltip
          id="my-tooltip"
          place="top"
          content={text}
          isOpen={isOpen}
          positionStrategy="fixed"
          style={{
            background: "rgba(255, 255, 255,1)",
            color: "var(--Primary-500, #151829)",
            textAlign: "center",
            fontFamily: "Pally",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "13px",
            letterSpacing: "-0.14px",
            width: "130px",
            zIndex: 100,
          }}
          opacity={1}
        />
      </div>
    </>
  )
}

export default ToolTip
