import { axiosInstance } from "@/api/axiosInstance"
import { BannerApi } from "../../swagger/api"
import { Configuration } from "../../swagger/configuration"
import { getFirebaseIDToken } from "./auth/firebase"
import { useQuery } from "@tanstack/react-query"

const getAccessToken = async () => {
  const accessToken = await getFirebaseIDToken()
  return accessToken
}

const getApiConfig = () =>
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_API_URL,
    //@ts-ignore
    accessToken: getAccessToken,
  })

const apiConfiguration = getApiConfig()

const BANNER_API = new BannerApi(apiConfiguration, apiConfiguration.basePath, axiosInstance)

export const getBanners = async () => BANNER_API.getBanners()

export const useGetBanners = () => {
  const { data: { data: banners } = {}, ...rest } = useQuery(["banners"], () => getBanners(), {
    retry: 1,
    enabled: true,
    cacheTime: 1,
  })

  return {
    banners,
    ...rest,
  }
}
