import { register, updatePlayerEmail } from "@/api/auth/api"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { CreateAnalyticsAdEventRequest, CreateAnalyticsComicEventRequest } from "swagger/models"
import { createAdEvent, createGameEvent } from "./api"

export const useCreateAdEvent = () => {
  return useMutation({
    mutationKey: ["useAdAnalytics"],
    mutationFn: (body: CreateAnalyticsAdEventRequest) => createAdEvent(body),
  })
}

export const useCreateGameEvent = () => {
  return useMutation({
    mutationKey: ["useGameAnalytics"],
    mutationFn: (body: CreateAnalyticsComicEventRequest) => createGameEvent(body),
  })
}
